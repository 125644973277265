<template>
  <IndexCom @changeIndex="changeIndex" ref="indexCom">
      <component :is="indexComponent[isItemActive]"></component>
  </IndexCom>

</template>

<script>

  import IndexCom from '@/components/IndexCom'
  import {mapMutations, mapActions} from 'vuex'
  import {getNavConfig, getRouteConfig} from '@/plugins/route'
  export default {
    name: "Index",
    data() {
      return {
        isChoiceActive: 0,
        isLogin:false,
        isItemActive: 0,
        value1: "",
        indexComponent: [
          'ComponentIndex',
          'PatentSupermarket',
          'ScienceServices',
          'Qxt',
          'PatentTrusteeshipIndex',
          'ExaminationIndex',
          'SciencePolicy',
          'EncyclopediaOfIntellectualProperty',
          'MediaReport',
          'knowledgeDetail',
          'mediaReportDetail',
          'DemandHall',
          'demandDetail',
          'sciencePolicyDetail',
          'PatentSearch',
          'GoodDetail',
          'ResourceRetrieval',
          'ResourceDetail',
          'PatentSearch',
          'PatentSearchDetail',
          'QiIndex'
        ],
        indexSq: 0,
      }
    },
    components: {
      'IndexCom': IndexCom,
      'ComponentIndex': () => import('@/components/index/ComponentIndex'),
      'PatentSupermarket': () => import('@/components/index/PatentSupermarket'),
      'ScienceServices': () => import('@/components/index/ScienceServices'),
      'QiIndex': () => import('@/components/index/QiIndex'),
      'PatentTrusteeshipIndex': () => import('@/components/index/PatentTrusteeshipIndex'),
      'ExaminationIndex': () => import('@/components/index/ExaminationIndex'),
      'PolicyIndex': () => import('@/components/index/PolicyIndex'),
      'EncyclopediaOfIntellectualProperty': () => import('@/components/index/EncyclopediaOfIntellectualProperty'),
      'MediaReport': () => import('@/components/index/MediaReport'),
      'knowledgeDetail': () => import('@/components/index/knowledgeDetail'),
      'mediaReportDetail': () => import('@/components/index/mediaReportDetail'),
      'DemandHall': () => import('@/components/index/DemandHall'),
      'demandDetail': () => import('@/components/index/demandDetail'),
      // 'ResourceRetrieval': () => import('@/components/index/ResourceRetrieval'),
      'SciencePolicy': () => import('@/components/index/SciencePolicy'),
      // 'PatentSearch': () => import('@/components/index/PatentSearch'),
      'sciencePolicyDetail': () => import('@/components/index/sciencePolicyDetail'),
      'ResourceRetrieval':() => import ('@/components/index/ResourceRetrieval'),
      'ResourceDetail':() =>  import('@/components/index/ResourceDetail'),
      'PatentSearch':()=> import('@/components/index/PatentSearch'),
      'PatentSearchDetail':()=> import('@/components/index/PatentSearchDetail'),
      'Qxt':()=> import('@/pages/qxt/index'),


    },
    mounted() {
      let path = this.$route.params.pageId || this.$route.name;
      if(!path){
        this.$refs.indexCom.isItemActive = 0;
      }
    },
    watch: {
      $route: {
        // 判断路由，从good跳过来，点击tab会传递一个参数
        immediate: true, // 一旦监听到路由的变化立即执行
        handler(to, older) {
          let path = this.$route.params.pageId || this.$route.name;
            path = '/home/' + path;
            let match = getRouteConfig().filter(x => x.path === path);
            this.isItemActive = (match[0] && match[0].navIndex) || 0;
            if(this.isItemActive > 8){
              if(this.$refs.indexCom){
                this.$refs.indexCom.isItemActive = 0
              }
            }
            this.$emit("changeIndex", this.isItemActive);
            if (this.token && this.userId) {
              this.isLogin = true
            }
            if (JSON.stringify(to.params) !== '{}') {
              //this.isItemActive = to.params.index;
            }

        },

      },
      isItemActive(val) {

      }
    },
    methods: {
      changeIndex(index) {
        this.isItemActive = index;
      },
      goLogin() {
        this.$router.push({
          name: `login`,
          params: {
            type: 1
          }
        })
      },
      goRegister() {
        this.$router.push({
          name: `login`,
          params: {
            type: 4
          }
        })
      },
      chooseAnli(index) {
        this.isAnli = index
      },
    }
  }
</script>

<style scoped>



</style>
