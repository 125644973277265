<template>
  <div class="container">
    <index-component></index-component>
    <div
        class="root-in">
      <img :src="siteUrl" height="40px" />
      <div class="top-four">

        <div v-for="(item,index) in list_items" :key="index"
             :class="['top-four-span',{'item_span':isItemActive === index}]" @click="choose_item(index)">{{ item.name }}
        </div>

      </div>
    </div>

    <slot></slot>

    <footer-component></footer-component>
  </div>

</template>

<script>
import logo from "@/assets/logo.png";
import close from "@/assets/image/index/close.png";
import { getNavConfig, getRouteConfig } from "@/plugins/route";


import { getIndexFloorDetail } from "@/plugins/api/indexApi";
import axios from "axios";
import baseUrl from "../plugins/http/baseUrl";


export default {
  name: "IndexCom",
  watch: {
    $route: {
      immediate: true, // 一旦监听到路由的变化立即执行
      handler(to, from) {
        let pageId = to.params.pageId;
        getRouteConfig().forEach((item, i) => {
          if (item.path.split("/")[2] === pageId) {
            this.isItemActive = item.navIndex;
          }
        });


        // if(to.name === 'good'){
        //   //跳转详情需要将tab置为2
        //   this.isItemActive = 2
        // }
      }
    }
  },

  data() {
    return {
      keyword: "",
      siteTitle: "",
      siteUrl: "",
      other: {
        ad: {
          url: "",
          pic: ""
        }
      },
      erweiFlag: false,
      firstFlag: true,
      close: close,
      erwei: "",
      weModal: false,
      footHide: false,
      logo: logo,
      isAnli: 0,
      isLogin: false,
      dominCookie: "",
      isChoiceActive: 0,
      choice_search: [
        "高校专利",
        "科研成果"
      ],
      isItemActive: 0,
      list_items: getNavConfig()
    };
  },
  components: {
    "footerComponent": () => import("../components/index/homePageComponent/footerComponent"),
    "indexComponent": () => import("../components/index/indexHeader")
  },
  mounted() {
    this.$forceUpdate();
    this.getIndexShowDetail();
    axios.get(`${baseUrl}/v1/site/one`).then(res => {
      if (res.status === 200) {
        let data = res.data.result;
        this.siteUrl = data.logoImageUrl;
        // this.siteTitle = data.title;
        // this.siteKeywords = data.keywords;
        // this.siteDescription = data.description;
        // this.erwei = data.qrCode;
      }
    });
  },

  methods: {
    async getIndexShowDetail() {
      const json = await getIndexFloorDetail();
      if (json && json.code === 0) {
        if (json.result.other) {
          this.other = json.result.other;
        }

      }
    },
    choose_item(index) {
      this.isItemActive = index;
      let nav = getRouteConfig().filter(x => x.navIndex === index);

      this.$router.push({
        path: nav[0].path
      });
    },
    search() {
      if (this.isChoiceActive == 0) {
        this.$router.push({
          path: "ResourceRetrieval",
          query: {
            "keyword": this.keyword
          }
        });
      } else {
        this.$router.push({
          path: "PatentSearch",
          query: {
            "keyword": this.keyword
          }
        });
      }

    }

  }
};
</script>

<style scoped lang="scss">
.root-in {
  height: 70px;
  box-shadow: 0 6px 12px 0 rgba(220, 220, 220, 0.25);
  width: 1200px;
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
}

.top-four {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  margin-left: 110px;
}


.item_span {
  color: rgba(0, 64, 153, 1) !important;
  border-bottom: 2px solid rgba(0, 64, 153, 1);
}

.top-four-span {
  cursor: pointer;
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 17px;
  font-weight: 400;
  color: #333333;
}

</style>
