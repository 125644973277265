
export function getRouteConfig() {
  return [
    {
      name: 'list.vue',
      path: '/home/index',
      navIndex: 0
    },
    {
      name: 'patent-supermarket',
      path: '/home/patent-supermarket',
      navIndex: 1
    },
    {
      name: 'colleges',
      path: '/home/colleges',
      navIndex: 1
    },
    {
      name: 'storeList',
      path: '/home/storeList',
      navIndex: 1
    },
    {
      name: 'experts',
      path: '/home/experts',
      navIndex: 1
    },
    {
      name: 'OutsideStore',
      path: '/home/OutsideStore',
      navIndex: 1
    },
    {
      name: 'goodsList',
      path: '/home/goodsList',
      navIndex: 1
    },
    {
      name: 'good',
      path: 'good',
      navIndex: 1
    },
    {
      name: 'science-service',
      path: '/home/science-service',
      navIndex: 2
    },
    {
      name: 'serviceList',
      path: '/home/serviceList',
      navIndex: 2
    },
    {
      name: 'serviceDetail',
      path: '/home/serviceDetail',
      navIndex: 2
    },
    {
      name: 'servicePay',
      path: '/home/servicePay',
      navIndex: 2
    },
    {
      name: 'qixiao',
      path: '/home/qixiao',
      navIndex: 3
    },
    {
      name: 'patent-trust',
      path: '/home/patent-trust',
      navIndex: 4
    },
    {
      name: 'examination',
      path: '/home/examination',
      navIndex: 5
    },
    {
      name: 'policy',
      path: '/home/policy',
      navIndex: 6
    },
    {
      name: 'baike',
      path: '/home/baike',
      navIndex: 7
    },
    {
      name: 'media-report',
      path: '/home/media-report',
      navIndex: 8
    },
    {
      name: 'knowledgeDetail',
      path: '/home/knowledgeDetail',
      navIndex: 9
    },
    {
      name: 'mediaReportDetail',
      path: '/home/mediaReportDetail',
      navIndex: 10
    },
    {
      name: 'DemandHall',
      path: '/home/DemandHall',
      navIndex: 11
    },
    {
      name: 'demandDetail',
      path: '/home/demandDetail',
      navIndex: 12
    },
    {
      name: 'sciencePolicyDetail',
      path: '/home/sciencePolicyDetail',
      navIndex: 13
    },
    {
      name: 'ResourceRetrieval',
      path: '/home/ResourceRetrieval',
      navIndex: 16
    },
    {
      name: 'ResourceDetail',
      path: '/home/ResourceDetail',
      navIndex: 17
    },
    {
      name:'PatentSearch',
      path:'/home/PatentSearch',
      navIndex:18
    },
    {
      name:'PatentSearchDetail',
      path:'/home/PatentSearchDetail',
      navIndex:19
    },
    {
      name:'qxt_result',
      path:'/pages/qxt_result',
      navIndex:3
    },

  ];
}


export function getEnterpriseRouteConfig() {
  return [
    {
      name: 'enterprise-management-center',
      path: '/enterprise/enterprise-management-center',
      navIndex: 0
    },
    {
      name: 'enterprise-school-connection',
      path: '/enterprise/enterprise-school-connection',
      navIndex: 1
    },
    {
      name: 'patent-trusteeship',
      path: '/enterprise/patent-trusteeship',
      navIndex: 2
    },
    {
      name: 'enterprise-physical-examination',
      path: '/enterprise/enterprise-physical-examination',
      navIndex: 3
    },
    {
      name: 'science-policy',
      path: '/enterprise/science-policy',
      navIndex: 4
    },
    {
      name:'service-pay',
      path:'/enterprise/service-pay/:id',
      nwoIndex:5
    },
    {
      name:'annua-pay',
      path:'/enterprise/annua-pay',
      nwoIndex:6
    }
  ];
}

export function getNavConfig() {
  return [
    {name: '首页', index: 0},
    {name: '专利超市', index: 1},
    {name: '科技服务', index: 2},
    {name: '企校通', index: 3},
    {name: '专利托管', index: 4},
    {name: '企业测评', index: 5},
    {name: '科技政策', index: 6},
    {name: '知产百科', index: 7},
    {name: '媒体报道', index: 8},
  ];
}


